<template>
    <div class="xrow">
        <div class="col-2-3">
            <div class="form-group">
                <label>Título</label>
                <input type="text" class="form-control" v-model="item.titulo">
            </div>
            <div class="form-group">
                <label>Descrição</label>
                <input type="text" class="form-control" v-model="item.descricao">
            </div>
            <div class="xrow">
                <div class="col-1-3">
                    <div class="form-group">
                        <label>Ativo</label>
                        <select v-model="item.ativo" class="form-control">
                            <option :value="true">Sim</option>
                            <option :value="false">Não</option>
                        </select>
                    </div>
                </div>
                <div class="col-1-3">
                    <div class="form-group">
                        <label>Modo PDF</label>
                        <select v-model="item.disposicao" class="form-control">
                            <option value="P">Retrato</option>
                            <option value="L">Paisagem</option>
                        </select>
                    </div>
                </div>
                <div class="col-1-3">
                    <div class="form-group">
                        <label>Tipo de relatório</label>
                        <select v-model="item.tipo" class="form-control">
                            <option v-for="(tipo, idx) in tipos" :value="idx">{{ tipo }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-1-3">
                    <div class="form-group">
                        <label>Permite Download?</label>
                        <select v-model="item.permite_download" class="form-control">
                            <option :value="1">Sim</option>
                            <option :value="0">Não</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="form-group perfis">
                <label>Perfis de visualização ( para quem será exibido )</label>
                <label v-for="perfil in perfis">
                    <input type="checkbox" :value="perfil.id" v-model="item.perfis">
                    {{ perfil.descricao }}
                </label>
            </div>
        </div>
        <div class="col-1-3">
            <div class="form-group">
                <image-picker
                        label="Imagem (Recomendado 128 x 128 px)"
                        placeholder="Colar URL ou clique ao lado para upload"
                        v-model="item.imagem" />
                <img v-if="item.imagem" :src="item.imagem" alt="Preview imagem" class="thumbnail thumbnail-small"/>
            </div>
        </div>
    </div>
</template>

<script>

  import ImagePicker from '../../../components/ImagePicker';

  export default {
    name: 'dadosExibicao',
    components: {
      ImagePicker,
    },
    props: [
      'item',
      'perfis',
      'tipos',
    ],
  };

</script>
