<template>
    <modal name="relatorioEdit" height="auto" width="90%" :scrollable="true" :clickToClose="false">
        <CloseModal :close="close" />
        <div class="modalbox">
            <div class="xrow">
                <div class="alert alterar">
                    <small>
                        Atenção, ao utilizar a conexão do banco de dados do ERP, certifique-se de que o usuário
                        utilizado para integração tenha permissão de leitura nas tabelas envolvidas nos selects, caso contrário,
                        o relatório não retornará nenhuma informação.
                    </small>
                </div>
            </div>
            <div class="modalbox-content">
                <CTabs variant="tabs" class="nav-tabs-boxed">
                    <CTab title="Exibição">
                        <DadosExibicao
                            :item="item"
                            :perfis="perfis"
                            :tipos="tipos"
                        />
                    </CTab>
                    <CTab title="Parâmetros">
                        <Parametros
                            :item="item"
                        />
                    </CTab>
                    <CTab title="Query">
                        <Query
                            :item="item"
                        />
                    </CTab>
                    <CTab title="Formatação">
                        <FormatosColunas
                            :item="item"
                        />
                    </CTab>
                    <CTab title="Resumos">
                        <Resumos
                            :item="item"
                        />
                    </CTab>
                    <CTab title="Envio Automático">
                        <EnvioAutomatico
                            :item="item"
                        />
                    </CTab>
                </CTabs>
            </div>
        </div>
        <div class="modal-footer">
            <button
                :class="{ 'button button-success': true, 'button-disabled': validation.hasError() }"
                v-on:click="onSave"
                :disabled="validation.hasError()"
            >{{ (!!item && !!item.id) ? 'Atualizar Relatório' : 'Criar Relatório' }}</button>
            <button class="button" v-on:click="closeModal()">Fechar</button>
        </div>
    </modal>
</template>
<script>
import { Validator } from "simple-vue-validator";
import ImagePicker from "../../components/ImagePicker";
import DadosExibicao from "./Edit/DadosExibicao";
import EnvioAutomatico from "./Edit/EnvioAutomatico";
import Query from "./Edit/Query";
import Parametros from "./Edit/Parametros";
import FormatosColunas from "./Edit/FormatosColunas";
import Resumos from "./Edit/Resumos";
import CloseModal from "../../components/CloseModal";

export default {
  name: "relatorioEdit",
  components: {
    Parametros,
    Query,
    DadosExibicao,
    ImagePicker,
    Resumos,
    FormatosColunas,
    CloseModal,
    EnvioAutomatico,
  },
  data() {
    return {

    };
  },
  props: ["close", "onSave", "item", "perfis", "tipos"],
  validators: {
    "parametro.valor": function(value) {
      return this.parametro.tipo !== "TEXT" && this.parametro.tipo !== "IMAGE"
        ? Validator.value(value).required()
        : Validator.value(value);
    },
  },
  methods: {
    closeModal() {
      this.close();
    },
  },
};
</script>
