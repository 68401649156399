<template>
  <div class="xrow">
    <div class="xrow">
      <div class="col-1-4">
        <div class="form-group">
          <label>Ativar envio automático por email?</label>
          <select v-model="item.envia_automatico" class="form-control">
            <option :value="true">Sim</option>
            <option :value="false">Não</option>
          </select>
        </div>
      </div>
    </div>

    <div class="xrow" v-show="item.envia_automatico">
      <div class="xrow">
        <div class="col-1-4">
          <div class="form-group">
            <label for>Período:</label>
            <div class="form-group">
              <select class="form-control" v-model="item.envioAutomatico.periodo">
                <option v-for="(desc, key) in periodo" v-bind:value="key">
                  {{ desc }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <!--      -->
        <div class="col-1-4" v-show="item.envioAutomatico.periodo === 'WEEKLY'">
          <div class="form-group">
            <label for>Dia:</label>
            <div class="form-group">
              <select class="form-control" v-model="item.envioAutomatico.diasemana">
                <option v-for="(desc, key) in diaSemana" v-bind:value="key">
                  {{ desc }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <!--      -->
        <div class="col-1-4" v-show="item.envioAutomatico.periodo === 'MONTHLY'">
          <div class="form-group">
            <label for>Dia:</label>
            <div class="form-group">
              <select class="form-control" v-model="item.envioAutomatico.diames">
                <option v-for="day in getDaysOfMonth" v-bind:value="day">
                  {{ day }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <!--      -->
        <div class="col-1-4" v-show="!!item.envioAutomatico.periodo">
          <div class="form-group">
            <label for>Horário:</label>
            <datetime
              input-class="form-control"
              type="time"
              v-model="item.envioAutomatico.hora"
              :phrases="{ok: 'Confirmar', cancel: 'Fechar'}"
              value-zone="local"
            />
          </div>
        </div>
      </div>
      <!-- formatos disponíveis -->
      <div class="xrow">
        <div class="col-1-4">
          <div class="form-group">
            <label for>Formatos:</label>
            <label>
              <input type="checkbox" :value="true" v-model="item.envioAutomatico.envia_pdf">
              Pdf
            </label>
            <label>
              <input type="checkbox" :value="true" v-model="item.envioAutomatico.envia_xls">
              Xls
            </label>
          </div>
        </div>
      </div>
      <!-- observações -->
      <div class="xrow">
        <hr/>
        <div class="col-1-1">
          <h5>Modo de Utilização</h5>
          <p>
            <small>
              Para habilitar a funcionalidade de envio automático é necessário que todos os parâmetros tenham valor padrão.
              <br/>
              Os relatórios serão enviados automáticamente para os perfis selecionados no menu "Exibição".
            </small>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

const initialStateEnvio = () => ({
  periodo: "DAILY",
  diasemana: null,
  diames: null,
  hora: null,
  envia_pdf: false,
  envia_xls: false
});

export default {
  name: 'envioAutomatico',
  components: {},
  props: [
    'item',
  ],
  data() {
    return {
      periodo: {
        'DAILY': 'Diariamente',
        'WEEKLY': 'Semanalmente',
        'MONTHLY': 'Mensalmente',
      },
      diaSemana: {
        '1': 'Segunda-feira',
        '2': 'Terça-feira',
        '3': 'Quarta-feira',
        '4': 'Quinta-feira',
        '5': 'Sexta-feira',
        '6': 'Sábado',
        '7': 'Domingo',
      },
    }
  },
  computed: {
    getDaysOfMonth() {
      const date = new Date();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return new Date(year, month, 0).getDate();
    },
  },
  watch: {
    'item.envioAutomatico.periodo'() {
      this.item.envioAutomatico.diasemana = null;
      this.item.envioAutomatico.diames = null;
    },
    'item.envia_automatico'(value) {
      if (value) {
        this.item.envioAutomatico = initialStateEnvio();
        return;
      }

      this.item.envioAutomatico = {};
    },
  },
  methods: {}
};

</script>
