<style scoped>
    .resumo {
        border: 2px solid #ccc;
        margin-bottom: 16px;
        position: relative;
    }

    .resumo-content {
        overflow: hidden;
    }

    .resumo .delete-resumo {
        border: 2px solid #ccc;
        background-color: #aaa;
        color: #fff;
        display: block;
        width: 30px;
        height: 30px;
        font-weight: bold;
        font-size: 30px;
        text-align: center;
        border-radius: 50px;
        position: absolute;
        top: -15px;
        right: -15px;
        line-height: 0.85;
        cursor: pointer;
    }
</style>

<template>
    <div>
        <div class="xrow">
            <div class="col-1-1 actions edit-buttons">
                <button type="button" class="button button-primary" v-on:click="addResumo">
                    Adicionar Resumo
                </button>
            </div>
            <div class="col-1-1">
                <div class="form-group">
                    <label>Resumos/Agregadores para relatório</label>
                </div>

                <div class="resumo caption" v-if="item.resumos.length === 0">
                    Nenhum resumo vinculado ...
                </div>

                <div v-for="resumo in orderedResumos">
                    <div class="resumo">
                        <a class="delete-resumo" v-on:click="deleteResumo(resumo)">
                            &times;
                        </a>
                        <div class="resumo-content">
                            <div class="col-1-3">
                                <div class="form-group">
                                    <label>Tipo de agregador</label>
                                    <select class="form-control" v-model="resumo.agregador">
                                        <option value="COUNT">Contador numeral ( COUNT )</option>
                                        <option value="COUNT_DISTINCT">Contador distinto ( COUNT DISTINCT )</option>
                                        <option value="SUM">Soma ( SUM )</option>
                                        <option value="AVERAGE">Média ( AVG )</option>
                                        <option value="MIN">Menor valor ( MIN )</option>
                                        <option value="MAX">Maior valor ( MAX )</option>
                                        <option value="FIRST">Primeiro registro ( FIRST )</option>
                                        <option value="LAST">Último registro ( LAST )</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label>Ordem de exibição</label>
                                    <input class="form-control" type="text" v-model="resumo.ordem" />
                                </div>
                            </div>
                            <div class="col-1-3">
                                <div class="form-group">
                                    <label>Coluna ( Nome ou posição numeral )</label>
                                    <input class="form-control" type="text" v-model="resumo.coluna" />
                                </div>
                                <div class="form-group">
                                    <label>Label de Exibição</label>
                                    <input class="form-control" type="text" v-model="resumo.titulo" />
                                </div>
                            </div>
                            <div class="col-1-3">
                                <div class="form-group">
                                    <label>Formato de apresentação</label>
                                    <select class="form-control" v-model="resumo.formato">
                                        <option value="STRING">Texto plano</option>
                                        <option value="DATE">Data</option>
                                        <option value="DATETIME">Data e Hora (S/ Segundos)</option>
                                        <option value="DATETIMES">Data e Hora (C/ segundos)</option>
                                        <option value="CURRENCY">Valor monetário</option>
                                        <option value="PERCENT">Percentual</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="xrow">
            <div class="col-1-1">
                <h4>O que são os resumos?</h4>
                <p>
                    <small>
                        Resumos são contadores que podem ser adicionados ao final do relatório, com a contagem de algum campo, somatório geral, contagem distinta, etc.
                        <br>
                        A coluna poderá ser do tipo numérica ( iniciando em 0 "Zero") ou utilizando o próprio nome da coluna.
                    </small>
                </p>
            </div>
        </div>
    </div>
</template>

<script>

  import _orderBy from 'lodash/orderBy'
  import _max from 'lodash/max'
  import _filter from 'lodash/filter'

  const resumo = (ordem) => {
    return {
      ordem,
      titulo: "",
      agregador: "COUNT",
      coluna: "",
      formato: "STRING",
    }
  }

  export default {
    name: 'resumos',
    props: [
      'item',
    ],
    computed: {
      orderedResumos: function () {
        return _orderBy(this.item.resumos, 'ordem')
      }
    },
    data() {
      return {
      }
    },
    methods: {
      addResumo() {
        let max = _max(this.item.resumos, 'ordem')
        let _resumo = resumo( !!max ? +max.ordem + 1 : 1 )
        this.item.resumos.push(_resumo);
      },
      deleteResumo(resumo) {
        this.item.resumos = _filter(this.item.resumos, (p) => p !== resumo);
      }
    }
  };

</script>
