<style scoped>
    .formato-coluna {
        border: 2px solid #ccc;
        margin-bottom: 16px;
        position: relative;
    }

    .formato-coluna-content {
        overflow: hidden;
    }

    .formato-coluna .delete-formato-coluna {
        border: 2px solid #ccc;
        background-color: #aaa;
        color: #fff;
        display: block;
        width: 30px;
        height: 30px;
        font-weight: bold;
        font-size: 30px;
        text-align: center;
        border-radius: 50px;
        position: absolute;
        top: -15px;
        right: -15px;
        line-height: 0.85;
        cursor: pointer;
    }
</style>

<template>
    <div>
        <div class="xrow">
            <div class="col-1-1 actions edit-buttons">
                <button type="button" class="button button-primary" v-on:click="addFormatoColuna">
                    Adicionar Formatação
                </button>
            </div>
            <div class="col-1-1">
                <div class="form-group">
                    <label>Formatação de exibição das colnas</label>
                </div>

                <div class="formato-coluna caption" v-if="item.formatosColunas.length === 0">
                    Nenhum formato adicionado, com isso, todas as colunas tem como saída o que a query retornar.
                </div>

                <div v-for="formato in item.formatosColunas">
                    <div class="formato-coluna">
                        <a class="delete-formato-coluna" v-on:click="deleteFormatoColuna(formato)">
                            &times;
                        </a>
                        <div class="formato-coluna-content">
                            <div class="col-1-3">
                                <div class="form-group">
                                    <label>Coluna (texto ou numeral)</label>
                                    <input class="form-control" type="text" v-model="formato.coluna" />
                                </div>
                            </div>
                            <div class="col-1-3">
                                <div class="form-group">
                                    <label>Formato de apresentação</label>
                                    <select class="form-control" v-model="formato.formato">
                                        <option value="STRING">Texto plano</option>
                                        <option value="DATE">Data</option>
                                        <option value="DATETIME">Data e Hora (S/ Segundos)</option>
                                        <option value="DATETIMES">Data e Hora (C/ segundos)</option>
                                        <option value="CURRENCY">Valor monetário</option>
                                        <option value="PERCENT">Percentual</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="xrow">
            <div class="col-1-1">
                <h4>Como funcionam os formatos?</h4>
                <p>
                    <small>
                        Toda coluna de saída do seu relatório poderá ter um tipo de formatação, como moeda, data, porcentagem, etc.
                        A coluna poderá ser do tipo numérica ( iniciando em 0 "Zero") ou utilizando o próprio nome da coluna.
                        Uma atenção especial deve ser tomada quando utilizando numeração de coluna e se adiciona mais colunas na query,
                        com isso, a ordem entre elas pode variar, ocasionando formatação em outros campos.
                    </small>
                </p>
            </div>
        </div>
    </div>
</template>

<script>

  import _filter from 'lodash/filter'

  const formatoColuna = () => {
    return {
      coluna: "",
      formato: "STRING",
    }
  }

  export default {
    name: 'formatosColunas',
    components: {

    },
    props: [
      'item',
    ],
    data() {
      return {

      }
    },
    methods: {
      addFormatoColuna() {
        let formato = formatoColuna()
        this.item.formatosColunas.push(formato);
      },
      deleteFormatoColuna(formato) {
        this.item.formatosColunas = _filter(this.item.formatosColunas, (p) => p !== formato);
      }
    }
  };

</script>
