<style scoped>
    .codemirror {
        font-size: 10px;
    }
</style>
<style>
    .query-relatorio .codemirror .CodeMirror {
        height: 900px;
    }
</style>
<template>
    <div class="xrow query-relatorio">
        <div class="col-2-3">
            <div class="form-group">
                <label>Query a ser executada</label>
            </div>
            <div class="form-group">
                <label>Local de execução da Query</label>
                <select v-model="item.banco" class="form-control">
                    <option value="local">Banco de dados Versotech</option>
                    <option value="erp">Banco de dados ERP</option>
                </select>
            </div>
            <div class="codemirror">
                <codemirror v-model="item.query" :options="code_options"></codemirror>
            </div>
            <div>
                <small>
                    Aguarde jovem padawan, em um futuro não muito distante traremos algumas ações para você conseguir
                    validar sua query antes de colocá-la em produção.
                </small>
            </div>
        </div>
        <div class="col-1-3">
            <div class="form-group">
                <label>Modo de utilização</label>
                <p>
                    <small>
                        A query ao lado será executada após todos os parâmetros obrigatórios serem preenchidos,
                        as colunas de alias serão interpretadas com título de apresentação.
                    </small>
                </p>
                <p>
                    <small>
                        Para utilização de parâmetros, basta informar o nome do parâmetro com um prefixo de 2 pontos, exemplo:
                        <br>
                        <strong>
                        <pre>
SELECT *
  FROM PEDIDO
 WHERE ID = :PEDIDO_ID
                        </pre>
                        </strong>
                    </small>
                </p>
                <p>
                    <small>
                        Caso um parâmetro seja de preenchimento opcional, é necessário prestar atenção na montagem da query,
                        pois caso ele não preenchido, a linha em que ele se encontra é removida da query. Recomendamos sempre
                        que possível, a montagem de uma cláusula por linha, exemplo:
                        <strong>
                            <pre>
SELECT *
  FROM PEDIDO
 WHERE 1=1
   AND DTPEDIDO >= :DTINICIO
   AND DTPEDIDO <= :DTFIM
   AND CLIENTE  = :CODCLI
                            </pre>
                        </strong>
                        Desta forma, a linha em que parâmetro opcional se encontra será removida sem afetar o funcionamento da query
                    </small>
                </p>
                <p>
                    <small>
                        Além dos parâmetros cadastrados, é possível utilizar informações do usuário que está logado
                        no sistema e que está emitindo o relatório, e para isso, os seguintes parâmetros estão disponíveis:
                        <strong>
                            <pre>
:AUTH_ID
:AUTH_NOME
:AUTH_EMAIL
:AUTH_REPRESENTANTE_ID
:AUTH_OPERADOR_ID
:AUTH_SUPERVISOR_ID
:AUTH_GERENTE_ID
:AUTH_TIPO_CHAVE
:AUTH_ID_DO_TIPO
                            </pre>
                        </strong>
                        Ao utilizar parâmetro do usuário autenticado, é necessário prestar atenção nos campos utilizados,
                        pois alguns só são preenchidos de acordo com o tipo dele, ex: representante_id só é preenchido quando
                        o usuário é do tipo "REPRESENTANTE". É possível utilizar o parâmetro "AUTH_ID_DO_TIPO", que representa
                        o código de acordo com o tipo do usuário, caso for do tipo "OPERADOR", será retornado o valor preenchido
                        em "operador_id", caso for "SUPERVISOR", o valor preenchido em "supervisor_id". Já o parâmetro "AUTH_TIPO_CHAVE"
                        é a representação textual, como "REPRESENTANTE", "OPERADOR", "SUPERVISOR", "GERENTE", "CLIENTE", etc.
                    </small>
                </p>
            </div>
        </div>
    </div>
</template>

<script>

  import { codemirror } from 'vue-codemirror'

  // theme css
  import 'codemirror/lib/codemirror.css'
  import 'codemirror/theme/monokai.css'

  // language js
  import'codemirror/addon/selection/active-line.js'
  import 'codemirror/mode/sql/sql.js'
  import 'codemirror/addon/display/autorefresh.js'

  // autoCloseTags
  import'codemirror/addon/edit/closetag.js'

  export default {
    name: 'query',
    components: {
      codemirror
    },
    props: [
      'item',
    ],
    data() {
      return {
        code_options: {
          tabSize: 4,
          styleActiveLine: true,
          lineNumbers: true,
          line: true,
          mode: 'text/x-sql',
          autoRefresh:true,
          lineWrapping: true,
          theme: 'monokai',
          showCursorWhenSelecting: true,
          highlightSelectionMatches: {
            showToken: /\w/,
            annotateScrollbar: true
          },
        }
      }
    }
  };

</script>
