<style scoped>
    .parametro {
        border: 2px solid #ccc;
        margin-bottom: 16px;
        position: relative;
    }

    .parametro-content {
        overflow: hidden;
    }

    .parametro .delete-parametro {
        border: 2px solid #ccc;
        background-color: #aaa;
        color: #fff;
        display: block;
        width: 30px;
        height: 30px;
        font-weight: bold;
        font-size: 30px;
        text-align: center;
        border-radius: 50px;
        position: absolute;
        top: -15px;
        right: -15px;
        line-height: 0.85;
        cursor: pointer;
    }
</style>

<style>
    .parametro .CodeMirror {
        margin-top: 16px;
        font-size: 10px;
        height: 150px;
    }
</style>
<template>
    <div>
        <div class="xrow">
            <div class="col-1-1 actions edit-buttons">
                <button type="button" class="button button-primary" v-on:click="addParametro">
                    Adicionar Parâmetro
                </button>
            </div>
            <div class="col-1-1">
                <div class="form-group">
                    <label>Parâmetros para execução do relatório</label>
                </div>

                <div class="parametro caption" v-if="item.parametros.length === 0">
                    Nenhum parâmetro vinculado ...
                </div>

                <div v-for="parametro in orderedParams">
                    <div class="parametro">
                        <a class="delete-parametro" v-on:click="deleteParametro(parametro)">
                            &times;
                        </a>
                        <div class="parametro-content">
                            <div class="col-1-3">
                                <div class="form-group">
                                    <label>Ordem de exibição</label>
                                    <input class="form-control" type="text" v-model="parametro.ordem" />
                                </div>
                                <div class="form-group">
                                    <label>Tipo de parâmetro</label>
                                    <select class="form-control" v-model="parametro.tipo">
                                        <option value="TEXT">Texto simples</option>
                                        <option value="NUMBER">Numérico</option>
                                        <option value="DATE">Data</option>
                                        <option value="DATETIME">Data e hora</option>
                                        <option value="SELECT">Seleção Dropdown (Select)</option>
                                        <option value="CHECKBOX">Múltiplas Opções (Checkbox)</option>
                                        <option value="RADIO">Opção Única (Radio)</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label>Label de Exibição</label>
                                    <input class="form-control" type="text" v-model="parametro.label" />
                                </div>
                                <div class="form-group">
                                    <label>Nome do parâmetro</label>
                                    <input class="form-control" type="text" v-model="parametro.parametro" />
                                </div>
                                <div class="form-group">
                                    <label>
                                        <input type="checkbox" v-model="parametro.obrigatorio">
                                        Preenchimento obrigatório
                                    </label>
                                </div>
                            </div>

                            <div class="col-1-3">
                                <div class="form-group">
                                    <label>Tipo de valor padrão</label>
                                    <select class="form-control" v-model="parametro.valorpadrao_tipo">
                                        <option value="TEXT">Texto plano</option>
                                        <option value="SQL">Consulta SQL</option>
                                    </select>
                                    <div class="form-group">
                                        <label>Local de execução da Query</label>
                                        <select v-model="parametro.banco_valorpadrao" class="form-control" :disabled="parametro.valorpadrao_tipo !== 'SQL'">
                                            <option value="local">Banco de dados Versotech</option>
                                            <option value="erp">Banco de dados ERP</option>
                                        </select>
                                    </div>
                                    <codemirror
                                        v-model="parametro.valorpadrao"
                                        :options="code_options">
                                    </codemirror>
                                </div>
                            </div>

                            <div class="col-1-3">
                                <div class="form-group">
                                    <label>Opções (Para campos de multi seleção)</label>
                                    <select class="form-control" v-model="parametro.opcoes_tipo" :disabled="!opcoesVisible(parametro)">
                                        <option value="TEXT">Texto (separar por linha)</option>
                                        <option value="SQL">Consulta SQL</option>
                                    </select>

                                    <div class="form-group">
                                        <label>Local de execução da Query</label>
                                        <select v-model="parametro.banco_opcoes" class="form-control" :disabled="parametro.opcoes_tipo !== 'SQL'">
                                            <option value="local">Banco de dados Versotech</option>
                                            <option value="erp">Banco de dados ERP</option>
                                        </select>
                                    </div>

                                    <codemirror
                                        v-model="parametro.opcoes"
                                        :options="code_options">
                                    </codemirror>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="xrow">
            <div class="col-1-1">
                <h4>O que são os parâmetros?</h4>
                <p>
                    <small>
                        Parâmetros são campos de formulário a serem preenchidos antes de executarem um relatório, eles são repassados
                        para dentro da query, identificado pelo "Nome do parâmetro", que deve conter um texto sem espaços e caracteres especiais.
                    </small>
                </p>
                <br>
                <h4>Nome do parâmetro</h4>
                <p>
                    <small>
                        O Nome do parâmetro será o utilizado dentro da query, e será repassado com o valor obtido na tela de geração do relatório, como por exemplo <strong><pre>WHERE foo.id = :NOME_DO_PARAMETRO</pre></strong>
                    </small>
                </p>
                <br>
                <h4>Preenchimento do campo "Tipo de valor padrão"</h4>
                <p>
                    <small>
                        Para preenchimento de campos do tipo SQL, nota-se que estas consultas são executadas utilizando o banco de dados
                        da Versotech, ao executar uma consulta SQL, deve-se retornar um alias de nome <strong>"valor"</strong>, caso seja retornado
                        mais de uma linha na consulta, apenas a primeira será considerada
                    </small>
                </p>
                <br>
                <p>
                    <small>
                            Obtendo a data atual para já deixá-la preenchida:
                        <strong>
                            <pre>SELECT NOW()::DATE AS valor</pre>
                        </strong>
                    </small>
                </p>
                <br>
                <p>
                    <small>
                            Obtendo a data de 2 dias atrás
                        <strong>
                            <pre>SELECT (NOW() - INTERVAL '2 DAYS')::DATE AS valor</pre>
                        </strong>
                    </small>
                </p>
                <br>
                <h4>Preenchimento do campo "Opções"</h4>
                <p>
                    <small>
                        Este campo é utilizado preenchimento de opções a serem listadas quando o parâmetro
                        é dos tipos: "SELECT", "CHECKBOX" e "RADIO". Para utilização do formato SQL, nota-se que estas
                        consultas são executadas utilizando o banco de dados da Versotech, ao executar uma consulta SQL,
                        deve-se retornar 2 aliases de nome <strong>"chave"</strong> e <strong>"valor"</strong>,
                        o campo informado pela "chave" será o utilizado para envio no parâmetro, enquanto que "valor" será apenas para exibição.
                        Caso seja utilizado o tipo "Texto", basta informar um valor por linha, que o sistema entenderá como uma lista.
                    </small>
                </p>
                <p>
                    <small>
                            Consulta de lista de operadores para utilização em tipos SELECT e CHECKBOX
                        <strong>
                            <pre>
SELECT id      as chave,
       nome    as valor
  FROM colaborador
  JOIN colaborador_tipo
    ON colaborador_tipo.id = colaborador.tipo_id
 WHERE colaborador_tipo.chave = 'OPERADOR'</pre>
                        </strong>
                    </small>
                </p>
            </div>
        </div>
    </div>
</template>

<script>

  import { codemirror } from 'vue-codemirror'

  // theme css
  import 'codemirror/lib/codemirror.css'
  import 'codemirror/theme/monokai.css'

  // language js
  import 'codemirror/addon/selection/active-line.js'
  import 'codemirror/mode/sql/sql.js'
  import 'codemirror/addon/display/autorefresh.js'

  // autoCloseTags
  import 'codemirror/addon/edit/closetag.js'

  import _orderBy from 'lodash/orderBy'
  import _max from 'lodash/max'
  import _filter from 'lodash/filter'

  const parametro = (ordem) => {
    return {
      ordem,
      label: "",
      obrigatorio: true,
      opcoes: "",
      opcoes_tipo: "TEXT",
      parametro: "",
      tipo: "TEXT",
      valorpadrao: "",
      valorpadrao_tipo: "TEXT",
      banco_valorpadrao: "local",
      banco_opcoes: "local"
    }
  }

  export default {
    name: 'query',
    components: {
      codemirror
    },
    props: [
      'item',
    ],
    computed: {
      orderedParams: function () {
        return _orderBy(this.item.parametros, 'ordem')
      }
    },
    data() {
      return {
        code_options: {
          tabSize: 4,
          styleActiveLine: true,
          lineNumbers: true,
          line: true,
          mode: 'text/x-sql',
          autoRefresh:true,
          lineWrapping: true,
          theme: 'monokai',
          showCursorWhenSelecting: true,
          highlightSelectionMatches: {
            showToken: /\w/,
            annotateScrollbar: true
          },
        },
        code_options_readonly: {
          tabSize: 4,
          styleActiveLine: true,
          lineNumbers: true,
          line: true,
          mode: 'text/x-sql',
          autoRefresh:true,
          lineWrapping: true,
          theme: 'monokai',
          showCursorWhenSelecting: true,
          highlightSelectionMatches: {
            showToken: /\w/,
            annotateScrollbar: true
          },
        }
      }
    },
    methods: {
      opcoesVisible(parametro) {
        return ['SELECT', 'CHECKBOX', 'RADIO', 'RADIOBUTTON'].indexOf(parametro.tipo) >= 0;
      },
      addParametro() {
        let max = _max(this.item.parametros, 'ordem')
        let param = parametro( !!max ? +max.ordem + 1 : 1 )
        this.item.parametros.push(param);
      },
      deleteParametro(parametro) {
        this.item.parametros = _filter(this.item.parametros, (p) => p !== parametro);
      }
    }
  };

</script>
